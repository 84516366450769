import * as React from "react";
import Layout from "../../layouts/en";
import PageHead from "../../components/shared/PageHead";
import { Clients } from "../../data/components/homepage";
import TestimonialSlider from "../../components/pages/TestimonialSlider";
import content from "../../data/content/recommends/recommends-en";
import * as styles from "../../styles/pages/recommends/index.module.css";
import RecomArticles from "../../components/pages/RecomArticles";

const Recommends = (props) => {
  const { seo, articles, recommends, dir } = content;
  const { sRecomends } = styles;
  return (
    <Layout location={props.location} lang="en">
      <PageHead pageHeadData={seo} />
      <article className={sRecomends} lang="en" dir="rtl">
        <RecomArticles articles={articles} dir={dir} />
        <TestimonialSlider recommends={recommends} dir={dir} />
        <Clients />
      </article>
    </Layout>
  );
};

export default Recommends;
